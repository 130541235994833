// header
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $baseColor01;
	height: 60px;
	z-index: 10; }

.p-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;

  .p-head-title {
    width: 75px; }

  .p-head-title img {
    width: 100%; }

  .trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;

    .line {
      width: 18px;
      height: 3px;
      background: #000;
      transition: transform 1s $easeOutExpo; }

    .line--02 {
      margin: 2px 0; } } }

.p-head-title {
  @include font-size(14);
  text-transform: capitalize; }

.p-nav {
  background: #fff;
  padding: 60px 20px;
  position: fixed;
  width: 100%;
  left: 100%;
  top: 60px;
  height: 100vh;
  transition: transform 0.5s $easeOutExpo;
  z-index: 11;

  .p-nav-list {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-overflow-scrolling: touch; }

  .p-nav-item {
    @include font-size(24);
    margin-top: 25px;
    cursor: pointer;

    a {
      display: flex;
      align-items: center; }

    .en {
      font-family: 'Work Sans Medium';
      @include font-size(20);
      pointer-events: none;
      text-transform: uppercase; }

    .ja {
      @include font-size(12);
      position: relative;
      padding-left: 20px;
      pointer-events: none; }

    .ja:before {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 1px;
      background: #000; } }

  .p-nav-item--cta {
    overflow: hidden;
    border-radius: 5px; }

  .p-nav-item--cta a {
    background: #E13A43;
    padding: 20px;
    color: #fff;
    justify-content: center;

    .ja:before {
      background: #fff; } }

  .p-nav-item--has-lower > .item-trigger {
    position: relative;
    display: flex;
    align-items: center; }

  .p-nav-item--has-lower > .item-trigger:before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 9px;
    background: url('/assets/images/common/down-arrow.svg') no-repeat 0 0;
    background-size: 10px;
    transition: transform 0.4s $easeOutExpo;
    transform-origin: 50% 50%; }

  .p-nav-list__lower {
    display: none;
    padding: 20px 0 0 10px; }

  .p-nav-list__lower .nav-item {
    margin-top: 15px;

    &:first-child {
      margin-top: 0; } }

  .p-nav-list__lower .nav-item a {
    @include font-size(14);
    text-transform: capitalize;

    .en {
      @include font-size(14); } } }

// active
.is-menu-open {

  .p-head .trigger .line {
    transition: transform 1s $easeOutExpo; }

  .p-head .trigger .line--01 {
    transform: translateY(4px); }

  .p-head .trigger .line--03 {
    transform: translateY(-4px); }

  .p-nav {
    transform: translateX(-100%);
    transition: transform 0.5s $easeOutExpo; }

  .p-content,
  .p-lower-content {
    transform: translateX(-50%);
    transition: transform 0.5s $easeOutExpo; }

  .p-nav-item--has-lower > .is-item-open.item-trigger:before {
    transform: rotate(180deg);
    transition: transform 0.4s $easeOutExpo; } }

@media screen and (min-width: 1024px) {
  .header {
    display: flex;
    justify-content: space-between;
    height: 80px; }

  .p-head {
    width: 12%;

    .p-head-title {
      width: 100px; }

    .trigger {
      display: none; } }

  .p-nav {
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    padding: 10px;
    transition: none; }

  .p-nav .p-nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: visible; }

  .p-nav-list .item-trigger,
  .p-nav-item > a {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px; }

  .p-nav-item.p-nav-item--01 .item-trigger {
    padding-left: 45px;
    padding-right: 45px; }

  .p-nav .p-nav-item {
    margin-top: 0;
    position: relative;
    white-space: nowrap; }

  .p-nav .p-nav-item .en {
    @include font-size(14); }

  .p-nav .p-nav-item .ja {
    padding-left: 0;
    margin-top: 5px;
    @include font-size(10);

    &::before {
      content: none; } }

  .p-nav .p-nav-item--cta a {
    height: 100%;
    padding: 6px 30px; }

  .p-nav .p-nav-list__lower {
    position: absolute;
    left: 0;
    top: 60px;
    background: #fff;
    z-index: 11;
    padding: 20px 0;
    width: 100%; }

  .p-nav .p-nav-list__lower .nav-item {
    margin-top: 20px; }

  .p-nav .p-nav-list__lower .nav-item > a {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    .en {
      @include font-size(12); }

    .ja {
      margin-top: 0; } }


  // hover
  .p-nav .p-nav-item a:hover,
  .p-nav .p-nav-item .item-trigger:hover {
    opacity: 0.7;
    transition: opacity 0.25s $easeOutExpo; }

  // shown
  .is-menu-shown .header {
    transform: translateY(0%);
    transition: transform 0.5s $easeOutExpo; } }


@media screen and (min-width: 1280px) {
  .p-nav .p-nav-item--cta {
    margin-left: 30px; } }

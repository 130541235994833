
// footer
.footer {
  background: #eee;
  font-family: 'Helvetica Neue';

  &__info {
    padding: 30px 20px; }

  &__cta {
    background: #f8f8f8;
    padding: 40px 20px; }

  &__cta .footer-cta__hdg {
    @include font-size(20);
    text-align: center; }

  &__cta .footer-cta__txt {
    text-align: center;
    margin-top: 10px;
    @include font-size(12); }

  &__name {
    @include font-size(18);
    text-align: center;
    font-weight: 700; }

  &__data,
  &__detail {
    margin-top: 20px;
    text-align: center;
    li {
      @include font-size(12);
      margin-top: 5px; } }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .footer__block {
      width: 50%; } }

  &__sns {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;

    li {
      margin: 0 5px; }

    li a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px; }

    li.fb a {
      background: #3B5998; }

    li.yt a {
      background: #E62116; }

    li.tw a {
      background: #1DA1F2; }

    li.ig a {
      background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d); }

    li .icon-ig {
      background: url('/assets/images/common/ic_ig.png') no-repeat 50% 50%;
      background-size: contain;
      display: block;
      width: 20px;
      height: 20px; }

    li.tt a {
      background: linear-gradient(45deg, rgba(255,0,80,1) 0%, rgba(0,242,234,1) 100%); }

    li .icon-tt {
      background: url('/assets/images/common/ic_tt.svg') no-repeat 50% 50%;
      background-size: contain;
      display: block;
      width: 20px;
      height: 20px; }

    li span:before {
      color: #fff;
      font-size: 20px; } }

  &__copyright {
    padding: 20px 0;
    text-align: center; }

  &__body {
    display: none; } }

@media screen and (min-width: 1024px) {
  .footer .footer__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px; }

  .footer .footer__cta {
    padding: 20px; }

  .footer .footer__info {
    padding: 0 20px 20px; }

  .footer .footer__siteinfo {
    width: 50%; }

  .footer .footer__name {
    text-align: left; }

  .footer .footer__detail {
    display: flex;
    flex-wrap: wrap; }

  .footer .footer__data,
  .footer .footer__wrap {
    width: 50%; }

  .footer .footer__wrap {
    margin-top: 0; }

  .footer .footer__data {
    text-align: left;
    margin-top: 0; }

  .footer .footer__block {
    text-align: left; }

  .footer .footer__body {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding-top: 35px;
    @include font-size(12);
    text-transform: capitalize;
    line-height: 1.75; }

  .footer .footer__category {
    width: 50%;
    text-transform: capitalize; }

  .footer .footer__category__content {
    padding-left: 1em; }

  .footer .footer__category__name {
    margin-top: 5px; }

  .footer .footer__misc {
    width: 100%;
    margin-top: 30px; }

  .footer .footer__sns {
    width: 50%;
    margin-left: auto;
    margin-right: auto; }

  .footer .footer__sns li {
    margin: 0 10px; }

  .footer .footer__sns li a {
    width: 48px;
    height: 48px; }

  .footer .footer__sns li .icon-ig {
    width: 24px;
    height: 24px; }

  .footer .footer__sns li span:before {
    font-size: 24px; }

  .footer .footer__sns li a:hover {
    opacity: 0.7;
    transition: opacity 0.4s $easeOutExpo; } }


@media screen and (min-width: 1400px) {
  .footer .footer__content {
    margin: 0 auto;
    max-width: 1400px; }

  .footer .footer__cta {
    padding: 60px; }

  .footer .footer__info {
    padding: 40px 20px 20px; }

  .footer .footer__siteinfo {
    width: 66.6%; }

  .footer .footer__category__name {
    margin-top: 10px; }

  .footer .footer__category {
    width: 100%; }

  .footer .footer__body {
    width: 33.3%;
    padding-top: 65px; } }

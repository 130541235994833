@charset 'utf-8';
@import url('https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@400;700&display=swap');
@import '../../../../node_modules/sass-easing/easings';
@import '../../../../node_modules/ress/ress';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body {
  font-family: 'Work Sans', 'Noto Sans JP', 'Hiragino Kaku Gothic Pro','ヒラギノ角ゴ Pro W3', '游ゴシック', YuGothic, sans-serif;
  line-height: 1.6; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.6em; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none;
  transition: all .2s linear;
  color: #000; }

ul, ol {
  list-style-type: none; }
